import { useQuery } from "@tanstack/react-query";

import { auth } from "@/requests";

import { AUTH_KEY } from "..";

export const useAuth = () => {
  const coutryCode = import.meta.env.VITE_COUNTRY_CODE;
  const queryFn = async () => {
    return auth();
  };
  return useQuery({
    queryKey: [AUTH_KEY],
    queryFn,
    staleTime: 1000 * 60 * 10,
    // TODO replace in immer.js if needed
    select: (data) => {
      const userData = data.data?.customer;
      if (userData === undefined) {
        throw new Error("User data is undefined");
      }
      return {
        data: {
          customer: {
            ...userData,
            phone_number: (userData?.phone_number as string)?.replace(
              coutryCode,
              ""
            ),
          },
        },
      };
    },
  });
};
