import axios from "axios";

import { getUserInfo } from "@/requests";

const server = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 10000,
});

server.interceptors.request.use(
  async (request) => {
    if (
      !request.headers.Authorization ||
      !("Authorization" in request.headers) ||
      request.headers.Authorization === "Bearer null" ||
      request.headers.Authorization === "Bearer undefined"
    ) {
      try {
        const cognitoUser = await getUserInfo();
        const token = cognitoUser.tokens?.idToken;
        if (!token) throw Error("no token.");
        request.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        if (
          !["/login"].find((route) => window.location.href?.includes(route))
        ) {
          window.location.replace("/login");
          return Promise.reject(new Error("no token."));
        }
        if (request?.url !== "/customers") {
          // 登录前发的请求, 确认用户是需要先创建还是直接走验证码流程
          return Promise.reject(new Error("no token."));
        }
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

server.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error?.code === "ECONNABORTED") {
      return Promise.reject(new Error("ネットワークタイムアウト"));
    }
    if (error?.code === "ERR_NETWORK") {
      return Promise.reject(new Error("ネットワーク異常"));
    }
    const status = error?.response?.status;
    if (status) {
      switch (status) {
        case 400:
          break;
        case 409: // 登录逻辑用到这个状态码;
          break;
        case 401: {
          if (
            !window.location.href.includes("/login") &&
            !window.location.href.includes("/login-confirm") &&
            !window.location.href.includes("/select-device")
          ) {
            window.location.href = "/login";
            return Promise.reject(
              new Error("アカウントのログインがタイムアウトしました。")
            );
          }
          break;
        }
        case 403: {
          return Promise.reject(new Error("Forbidden."));
        }
        case 404: {
          return Promise.reject(new Error("Not Found."));
        }
        case 500: {
          return Promise.reject(new Error("サーバーエラー"));
        }
        default: {
          return Promise.reject(new Error("未知のエラー"));
        }
      }
    }
    return Promise.reject(error.response);
  }
);

export default server;
