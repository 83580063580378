import { server } from "@/requests";

export interface UpdateCustomerData {
  name: string;
  company_name?: string;
  phone_number?: string;
}

export const updateCustomer = (data: UpdateCustomerData) => {
  return server.patch("/customers", data);
};
