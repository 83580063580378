import QRCode from "react-qr-code";

import { CodeBoard, Loading } from "@/components";
import { useGetBizKeyById } from "@/queries";
import { useSelectBizKeyApplication } from "@/redux/slices/bizkey";

interface EntryCodeDialogProps {
  visible: boolean;
}
export const EntryCodeDialog: React.FC<EntryCodeDialogProps> = ({
  visible,
}) => {
  if (!visible) return null;
  else return <EntryCodeDialogContent />;
};

export const EntryCodeDialogContent = () => {
  const { selectedApplicationId } = useSelectBizKeyApplication();
  const { data, isLoading, isSuccess } = useGetBizKeyById(
    selectedApplicationId
  );
  const application = data?.data;

  if (isLoading) {
    return (
      <>
        <div className="mx-auto h-48 w-48 bg-neutral-200/10 p-12">
          <Loading size="6rem" type="circular" />
        </div>
        <div className="text-xs">
          {/* <div className="my-5 text-center leading-4">00</div> */}
          <p className="mt-5 text-neutral-1000">
            スキャナーがない場合は、以下のパスワードを入力してください。
          </p>
          <CodeBoard className="my-1">{"「------」"}</CodeBoard>
          {/* <p className="text-xxs text-neutral-100">
            このパスワードは60秒ごとに更新されます。
          </p> */}
        </div>
      </>
    );
  }
  if (isSuccess) {
    return (
      <>
        <QRCode
          value={JSON.stringify(application?.credential?.secret ?? "error")}
          className="mx-auto h-48 w-48"
        />

        <div className="text-xs">
          {/* <Countdown
            key={otp}
            duration={timeRemaining()}
            onCoutdownEnd={handleEntryCodeExpired}
            className="my-5 justify-center"
          /> */}
          <p className="mt-5 text-neutral-1000">
            スキャナーがない場合は、以下のパスワードを入力してください。
          </p>
          <CodeBoard className="my-1">
            {application?.credential?.secret ?? "error"}
          </CodeBoard>
          {/* <p className="text-xxs text-neutral-100">
            このパスワードは60秒ごとに更新されます。
          </p> */}
        </div>
      </>
    );
  }
};
