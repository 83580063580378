import { useNavigate } from "react-router-dom";

import {
  ApplyInfoCard,
  ApplyInfoCardBody,
  ApplyInfoCardBodyItem,
  ApplyInfoCardHeader,
} from "@/components";
import { useGetUserCategory } from "@/hooks";
import type { UserCategory, UserInfo } from "@/types";

interface UserInfoCardProps {
  user: UserInfo | undefined;
  canEdit?: boolean;
  type?: UserCategory;
}

export const UserInfoCard: React.FC<UserInfoCardProps> = ({
  user,
  canEdit,
  type = "resident",
}) => {
  const navigate = useNavigate();
  const category = useGetUserCategory();
  const handleEditClick = () => {
    navigate(`/identity/${category}`);
  };
  return (
    <ApplyInfoCard className="mt-4">
      <ApplyInfoCardHeader className="flex justify-between text-sm">
        <p className="font-medium">申請者の情報</p>
        {canEdit && (
          <button className="text-xxs text-primary" onClick={handleEditClick}>
            編集
          </button>
        )}
      </ApplyInfoCardHeader>
      <ApplyInfoCardBody>
        {type === "services" && (
          <>
            <ApplyInfoCardBodyItem title="会 社 名：">
              <span>{user?.customer?.company_name}</span>
            </ApplyInfoCardBodyItem>
            <ApplyInfoCardBodyItem title="名　前：">
              <span>{user?.customer?.name}</span>
            </ApplyInfoCardBodyItem>
          </>
        )}
        {type === "resident" && (
          <>
            <ApplyInfoCardBodyItem title="名　前：">
              <span>{user?.customer?.name}</span>
            </ApplyInfoCardBodyItem>
            <ApplyInfoCardBodyItem title="携帯番号：">
              <span>{user?.customer?.phone_number}</span>
            </ApplyInfoCardBodyItem>
          </>
        )}
      </ApplyInfoCardBody>
    </ApplyInfoCard>
  );
};
