import { Navigate, useNavigate } from "react-router-dom";

import { BizKeyLOGO, PageNotFound } from "@/components";
import { useGetUserCategory } from "@/hooks";
import { useAuth } from "@/queries";
import { noti } from "@/shared/utils";

import { AgentApplicationForm } from "./AgentApplicationForm";
import { ResidentApplicationForm } from "./ResidentApplicationForm";

export default function EntryApplyIndexWrapper() {
  const category = useGetUserCategory();
  const navigate = useNavigate();
  const {
    data: userData,
    isSuccess: isAuthSucceed,
    isError: isAuthError,
    isLoading,
  } = useAuth();

  if (isAuthError) {
    noti("ユーザー情報の取得に失敗しました。");
    navigate(-1);
  }

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 mx-4 h-screen w-screen bg-neutral-20">
        <BizKeyLOGO />
      </div>
    );
  }

  if (isAuthSucceed) {
    const userInfo = userData.data?.customer;
    if (
      category === "services" &&
      (!userInfo?.company_name || !userInfo?.name)
    ) {
      return <Navigate to={`/identity/${category}`} replace />;
    }
    if (
      category === "resident" &&
      (!userInfo?.name || !userInfo?.phone_number)
    ) {
      return <Navigate to={`/identity/${category}`} replace />;
    }
  }

  switch (category) {
    case "services":
      return <AgentApplicationForm />;
    case "resident":
      return <ResidentApplicationForm />;
    default:
      return <PageNotFound />;
  }
}
