import { useMutation } from "@tanstack/react-query";

import { createUser, logout, sendCode } from "@/requests";

export const usePreLogin = () => {
  return useMutation({ mutationFn });
};
const mutationFn = async ({ username }: { username: string }) => {
  try {
    await logout();
    await createUser({ email: username });
    return sendCode({ username });
  } catch (error) {
    const errorInstance = error as { status?: number; data: { type: string } };
    if (
      errorInstance?.status === 409 &&
      errorInstance?.data?.type === "UserExists"
    ) {
      return sendCode({ username });
    }
    return Promise.reject(error);
  }
};
