import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  ApplyInfoCard,
  ApplyInfoCardBody,
  ApplyInfoCardBodyItem,
  ApplyInfoCardHeader,
  Button,
  OverlayLoading,
  Popup,
  PopupAction,
  PopupMain,
} from "@/components";
import { UserInfoCard } from "@/components/info-card/UserInfoCard";
import { useModal } from "@/hooks";
import {
  OverlapEntryTimeError,
  useAuth,
  useCreateBizKeyOfServices,
} from "@/queries";
import type { BizKeyApplicationState } from "@/redux/slices/bizkey";
import { useSelectBizKeyApplication } from "@/redux/slices/bizkey";
import { noti } from "@/shared/utils";

export const AgentApplyConfirm = () => {
  const applicationDetail = useSelectBizKeyApplication();
  const navigate = useNavigate();
  const { visible, toggle } = useModal();
  const { mutateAsync, isPending } = useCreateBizKeyOfServices();
  const { data, isError, error, isFetching } = useAuth();
  const user = data?.data;

  const handleComfirmClick = async () => {
    mutateAsync()
      .then(() => {
        navigate("../success");
      })
      .catch((error) => {
        if (error instanceof OverlapEntryTimeError) {
          toggle();
          return;
        }
        noti(error?.message || error.toString());
      });
  };

  // get auth info failed, but should not block the process
  if (isError) {
    noti(error?.message || error.toString());
  }

  useEffect(() => {
    if (!applicationDetail.property) {
      navigate("/apply/services");
    }
  }, [applicationDetail]);

  return (
    <>
      <div className="flex flex-1 flex-col">
        <UserInfoCard user={user} type="services" canEdit />
        <ApplyInfoCard className="mt-4">
          <ApplyInfoCardHeader className="flex justify-between text-sm">
            <p className="font-medium">申請内容</p>
            <button
              className="text-xxs text-primary"
              onClick={() => navigate(-1)}
            >
              修正
            </button>
          </ApplyInfoCardHeader>
          <ApplyInfoCardBody>
            <ApplyInfoCardBodyItem title="物　　件：">
              <span>{applicationDetail?.property?.label}</span>
            </ApplyInfoCardBodyItem>
            <ApplyInfoCardBodyItem title="入館日時：">
              {/* TODO 所有的入馆日时的逻辑统一 */}
              <p>{formatTime(applicationDetail)}</p>
              <AccessMethodCaption applicationDetail={applicationDetail} />
            </ApplyInfoCardBodyItem>
            <ApplyInfoCardBodyItem title="コメント：">
              <span>{applicationDetail?.comment || "無し"}</span>
            </ApplyInfoCardBodyItem>
          </ApplyInfoCardBody>
        </ApplyInfoCard>
        <div className="flex-1"></div>
        <div className="mt-auto">
          <Button onClick={handleComfirmClick} loading={isPending}>
            確認して提出する
          </Button>
          <p className="mt-2 text-xs text-neutral-100">
            ※&nbsp;営業時間外の申請は、承認にお時間をいただく場合がございます。
          </p>
        </div>
      </div>
      <OverlayLoading visible={isFetching} />
      <Popup visible={visible}>
        <PopupMain>
          時間帯が既存の申請と重なっているため、重複して申請を作成することはできません。
        </PopupMain>
        <PopupAction>
          <Button onClick={toggle}>閉じる</Button>
        </PopupAction>
      </Popup>
    </>
  );
};

export const AccessMethodCaption = ({
  applicationDetail,
}: {
  applicationDetail: BizKeyApplicationState;
}) => {
  if (applicationDetail.access_method === "onetime") {
    return <p className="mt-2 text-primary">一回限り</p>;
  } else {
    const { time, day_of_week } = applicationDetail;
    return (
      <div className="mt-2 text-primary">
        <p>
          <span>毎週の</span>
          <span>{day_of_week?.map((day) => day.label).join("、")}</span>
        </p>
        {time && (
          <p>{`${time[0][0]}:${time[0][1]}-${time[1][0]}:${time[1][1]}`}</p>
        )}
        <p>定期的に</p>
      </div>
    );
  }
};

function formatTime(applicationDetail: BizKeyApplicationState): string {
  const { access_method, start_date, time, valid_duration } = applicationDetail;
  if (access_method === "onetime" && start_date && time) {
    return `${dayjs(start_date).format("YYYY年MM月DD日")} ${time[0][0]}:${time[0][1]}-${time[1][0]}:${time[1][1]}`;
  } else if (access_method === "repeat" && valid_duration && start_date) {
    const now = new Date(start_date);
    const after = new Date(start_date);
    after.setMonth(now.getMonth() + valid_duration.value / 30);
    return `${dayjs(now).format("YYYY年MM月DD日")}-${dayjs(after).format("MM月DD日")}`;
  } else {
    return "-";
  }
}
